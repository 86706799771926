import {createRouter, createWebHistory} from "vue-router";
import UserDashboard from "./components/UserDashboard.vue";
import UserDetails from "./components/UserDetails.vue";
import UserProfile from "./components/UserProfile.vue";

const routes = [
    {
        name: "UserDashboard",
        component: UserDashboard,
        path: "/"
    },
    {
        name: "UserDetails",
        component: UserDetails,
        path: "/details"
    },
    {
        name: "UserProfile",
        component: UserProfile,
        path: "/profile"
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;