<template>
    <div class="content-details">
        <div class="top-header">
            <div class="search-row">
                <div class="input-search">
                    <input type="text" class="form-control" placeholder="Search for a specific exposure...">
                    <button type="submit" class="search-btn">
                        <img src="~@/assets/images/search.svg" alt="">
                    </button>
                </div>
            </div>
        </div>
        <tabs :options="{ useUrlFragment: false }">
            <tab name="All" suffix=" <span class='counter'>20</span>">
                <div class="detail-rows">
                    <div class="container">
                        <div class="row header-row">
                            <div class="col col-1">Date</div>
                            <div class="col col-2">Тype</div>
                            <div class="col col-3">Last Scan</div>
                            <div class="col col-4">Last Exposure</div>
                            <div class="col col-5">Last Active</div>
                            <div class="col col-6">Next Action</div>
                            <div class="col col-7">Status</div>
                            <div class="col col-8">Trust</div>
                        </div>
                        <div class="row data-row">
                            <div class="col col-1" data-title="Date">BOSCO LLC</div>
                            <div class="col col-2" data-title="Тype">Data Broker</div>
                            <div class="col col-3" data-title="Last Scan">Unscannable</div>
                            <div class="col col-4" data-title="Last Exposure">12/12/24 01:03:24am</div>
                            <div class="col col-5" data-title="Last Active">12/12/24 01:03:24am</div>
                            <div class="col col-6" data-title="Next Action">Request Delete</div>
                            <div class="col col-7" data-title="Status">Exposed</div>
                            <div class="col col-8" data-title="Trust">Negative</div>
                        </div>
                        <div class="row data-row">
                            <div class="col col-1" data-title="Date">BOSCO LLC</div>
                            <div class="col col-2" data-title="Тype">Data Broker</div>
                            <div class="col col-3" data-title="Last Scan">Unscannable</div>
                            <div class="col col-4" data-title="Last Exposure">12/12/24 01:03:24am</div>
                            <div class="col col-5" data-title="Last Active">12/12/24 01:03:24am</div>
                            <div class="col col-6" data-title="Next Action">Request Delete</div>
                            <div class="col col-7" data-title="Status">Exposed</div>
                            <div class="col col-8" data-title="Trust">Negative</div>
                        </div>
                        <div class="row data-row">
                            <div class="col col-1" data-title="Date">BOSCO LLC</div>
                            <div class="col col-2" data-title="Тype">Data Broker</div>
                            <div class="col col-3" data-title="Last Scan">Unscannable</div>
                            <div class="col col-4" data-title="Last Exposure">12/12/24 01:03:24am</div>
                            <div class="col col-5" data-title="Last Active">12/12/24 01:03:24am</div>
                            <div class="col col-6" data-title="Next Action">Request Delete</div>
                            <div class="col col-7" data-title="Status">Exposed</div>
                            <div class="col col-8" data-title="Trust">Negative</div>
                        </div>
                        <div class="row data-row">
                            <div class="col col-1" data-title="Date">BOSCO LLC</div>
                            <div class="col col-2" data-title="Тype">Data Broker</div>
                            <div class="col col-3" data-title="Last Scan">Unscannable</div>
                            <div class="col col-4" data-title="Last Exposure">12/12/24 01:03:24am</div>
                            <div class="col col-5" data-title="Last Active">12/12/24 01:03:24am</div>
                            <div class="col col-6" data-title="Next Action">Request Delete</div>
                            <div class="col col-7" data-title="Status">Exposed</div>
                            <div class="col col-8" data-title="Trust">Negative</div>
                        </div>
                        <div class="row data-row">
                            <div class="col col-1" data-title="Date">BOSCO LLC</div>
                            <div class="col col-2" data-title="Тype">Data Broker</div>
                            <div class="col col-3" data-title="Last Scan">Unscannable</div>
                            <div class="col col-4" data-title="Last Exposure">12/12/24 01:03:24am</div>
                            <div class="col col-5" data-title="Last Active">12/12/24 01:03:24am</div>
                            <div class="col col-6" data-title="Next Action">Request Delete</div>
                            <div class="col col-7" data-title="Status">Exposed</div>
                            <div class="col col-8" data-title="Trust">Negative</div>
                        </div>
                        <div class="row data-row">
                            <div class="col col-1" data-title="Date">BOSCO LLC</div>
                            <div class="col col-2" data-title="Тype">Data Broker</div>
                            <div class="col col-3" data-title="Last Scan">Unscannable</div>
                            <div class="col col-4" data-title="Last Exposure">12/12/24 01:03:24am</div>
                            <div class="col col-5" data-title="Last Active">12/12/24 01:03:24am</div>
                            <div class="col col-6" data-title="Next Action">Request Delete</div>
                            <div class="col col-7" data-title="Status">Exposed</div>
                            <div class="col col-8" data-title="Trust">Negative</div>
                        </div>
                        <div class="row data-row">
                            <div class="col col-1" data-title="Date">BOSCO LLC</div>
                            <div class="col col-2" data-title="Тype">Data Broker</div>
                            <div class="col col-3" data-title="Last Scan">Unscannable</div>
                            <div class="col col-4" data-title="Last Exposure">12/12/24 01:03:24am</div>
                            <div class="col col-5" data-title="Last Active">12/12/24 01:03:24am</div>
                            <div class="col col-6" data-title="Next Action">Request Delete</div>
                            <div class="col col-7" data-title="Status">Exposed</div>
                            <div class="col col-8" data-title="Trust">Negative</div>
                        </div>
                        <div class="row data-row">
                            <div class="col col-1" data-title="Date">BOSCO LLC</div>
                            <div class="col col-2" data-title="Тype">Data Broker</div>
                            <div class="col col-3" data-title="Last Scan">Unscannable</div>
                            <div class="col col-4" data-title="Last Exposure">12/12/24 01:03:24am</div>
                            <div class="col col-5" data-title="Last Active">12/12/24 01:03:24am</div>
                            <div class="col col-6" data-title="Next Action">Request Delete</div>
                            <div class="col col-7" data-title="Status">Exposed</div>
                            <div class="col col-8" data-title="Trust">Negative</div>
                        </div>
                        <div class="row data-row">
                            <div class="col col-1" data-title="Date">BOSCO LLC</div>
                            <div class="col col-2" data-title="Тype">Data Broker</div>
                            <div class="col col-3" data-title="Last Scan">Unscannable</div>
                            <div class="col col-4" data-title="Last Exposure">12/12/24 01:03:24am</div>
                            <div class="col col-5" data-title="Last Active">12/12/24 01:03:24am</div>
                            <div class="col col-6" data-title="Next Action">Request Delete</div>
                            <div class="col col-7" data-title="Status">Exposed</div>
                            <div class="col col-8" data-title="Trust">Negative</div>
                        </div>
                        <div class="row data-row">
                            <div class="col col-1" data-title="Date">BOSCO LLC</div>
                            <div class="col col-2" data-title="Тype">Data Broker</div>
                            <div class="col col-3" data-title="Last Scan">Unscannable</div>
                            <div class="col col-4" data-title="Last Exposure">12/12/24 01:03:24am</div>
                            <div class="col col-5" data-title="Last Active">12/12/24 01:03:24am</div>
                            <div class="col col-6" data-title="Next Action">Request Delete</div>
                            <div class="col col-7" data-title="Status">Exposed</div>
                            <div class="col col-8" data-title="Trust">Negative</div>
                        </div>
                        <div class="row data-row">
                            <div class="col col-1" data-title="Date">BOSCO LLC</div>
                            <div class="col col-2" data-title="Тype">Data Broker</div>
                            <div class="col col-3" data-title="Last Scan">Unscannable</div>
                            <div class="col col-4" data-title="Last Exposure">12/12/24 01:03:24am</div>
                            <div class="col col-5" data-title="Last Active">12/12/24 01:03:24am</div>
                            <div class="col col-6" data-title="Next Action">Request Delete</div>
                            <div class="col col-7" data-title="Status">Exposed</div>
                            <div class="col col-8" data-title="Trust">Negative</div>
                        </div>
                        <div class="row data-row">
                            <div class="col col-1" data-title="Date">BOSCO LLC</div>
                            <div class="col col-2" data-title="Тype">Data Broker</div>
                            <div class="col col-3" data-title="Last Scan">Unscannable</div>
                            <div class="col col-4" data-title="Last Exposure">12/12/24 01:03:24am</div>
                            <div class="col col-5" data-title="Last Active">12/12/24 01:03:24am</div>
                            <div class="col col-6" data-title="Next Action">Request Delete</div>
                            <div class="col col-7" data-title="Status">Exposed</div>
                            <div class="col col-8" data-title="Trust">Negative</div>
                        </div>
                        <div class="row data-row">
                            <div class="col col-1" data-title="Date">BOSCO LLC</div>
                            <div class="col col-2" data-title="Тype">Data Broker</div>
                            <div class="col col-3" data-title="Last Scan">Unscannable</div>
                            <div class="col col-4" data-title="Last Exposure">12/12/24 01:03:24am</div>
                            <div class="col col-5" data-title="Last Active">12/12/24 01:03:24am</div>
                            <div class="col col-6" data-title="Next Action">Request Delete</div>
                            <div class="col col-7" data-title="Status">Exposed</div>
                            <div class="col col-8" data-title="Trust">Negative</div>
                        </div>
                        <div class="row data-row">
                            <div class="col col-1" data-title="Date">BOSCO LLC</div>
                            <div class="col col-2" data-title="Тype">Data Broker</div>
                            <div class="col col-3" data-title="Last Scan">Unscannable</div>
                            <div class="col col-4" data-title="Last Exposure">12/12/24 01:03:24am</div>
                            <div class="col col-5" data-title="Last Active">12/12/24 01:03:24am</div>
                            <div class="col col-6" data-title="Next Action">Request Delete</div>
                            <div class="col col-7" data-title="Status">Exposed</div>
                            <div class="col col-8" data-title="Trust">Negative</div>
                        </div>
                        <div class="row data-row">
                            <div class="col col-1" data-title="Date">BOSCO LLC</div>
                            <div class="col col-2" data-title="Тype">Data Broker</div>
                            <div class="col col-3" data-title="Last Scan">Unscannable</div>
                            <div class="col col-4" data-title="Last Exposure">12/12/24 01:03:24am</div>
                            <div class="col col-5" data-title="Last Active">12/12/24 01:03:24am</div>
                            <div class="col col-6" data-title="Next Action">Request Delete</div>
                            <div class="col col-7" data-title="Status">Exposed</div>
                            <div class="col col-8" data-title="Trust">Negative</div>
                        </div>
                    </div>
                </div>
            </tab>
            <tab name="Requested" suffix=" <span class='counter'>4</span>">
                <div class="detail-rows">
                    <div class="container">
                        <div class="row header-row">
                            <div class="col col-1">Date</div>
                            <div class="col col-2">Тype</div>
                            <div class="col col-3">Last Scan</div>
                            <div class="col col-4">Last Exposure</div>
                            <div class="col col-5">Last Active</div>
                            <div class="col col-6">Next Action</div>
                            <div class="col col-7">Status</div>
                            <div class="col col-8">Trust</div>
                        </div>
                        <div class="row data-row">
                            <div class="col col-1" data-title="Date">BOSCO LLC</div>
                            <div class="col col-2" data-title="Тype">Data Broker</div>
                            <div class="col col-3" data-title="Last Scan">Unscannable</div>
                            <div class="col col-4" data-title="Last Exposure">12/12/24 01:03:24am</div>
                            <div class="col col-5" data-title="Last Active">12/12/24 01:03:24am</div>
                            <div class="col col-6" data-title="Next Action">Request Delete</div>
                            <div class="col col-7" data-title="Status">Exposed</div>
                            <div class="col col-8" data-title="Trust">Negative</div>
                        </div>
                        <div class="row data-row">
                            <div class="col col-1" data-title="Date">BOSCO LLC</div>
                            <div class="col col-2" data-title="Тype">Data Broker</div>
                            <div class="col col-3" data-title="Last Scan">Unscannable</div>
                            <div class="col col-4" data-title="Last Exposure">12/12/24 01:03:24am</div>
                            <div class="col col-5" data-title="Last Active">12/12/24 01:03:24am</div>
                            <div class="col col-6" data-title="Next Action">Request Delete</div>
                            <div class="col col-7" data-title="Status">Exposed</div>
                            <div class="col col-8" data-title="Trust">Negative</div>
                        </div>
                        <div class="row data-row">
                            <div class="col col-1" data-title="Date">BOSCO LLC</div>
                            <div class="col col-2" data-title="Тype">Data Broker</div>
                            <div class="col col-3" data-title="Last Scan">Unscannable</div>
                            <div class="col col-4" data-title="Last Exposure">12/12/24 01:03:24am</div>
                            <div class="col col-5" data-title="Last Active">12/12/24 01:03:24am</div>
                            <div class="col col-6" data-title="Next Action">Request Delete</div>
                            <div class="col col-7" data-title="Status">Exposed</div>
                            <div class="col col-8" data-title="Trust">Negative</div>
                        </div>
                        <div class="row data-row">
                            <div class="col col-1" data-title="Date">BOSCO LLC</div>
                            <div class="col col-2" data-title="Тype">Data Broker</div>
                            <div class="col col-3" data-title="Last Scan">Unscannable</div>
                            <div class="col col-4" data-title="Last Exposure">12/12/24 01:03:24am</div>
                            <div class="col col-5" data-title="Last Active">12/12/24 01:03:24am</div>
                            <div class="col col-6" data-title="Next Action">Request Delete</div>
                            <div class="col col-7" data-title="Status">Exposed</div>
                            <div class="col col-8" data-title="Trust">Negative</div>
                        </div>
                    </div>
                </div>
            </tab>
            <tab name="Not Found" suffix=" <span class='counter'>0</span>">
                <div class="detail-rows">
                    <div class="container">
                        <div class="no-data">No Data Found</div>
                    </div>
                </div>
            </tab>
            <tab name="Closed" suffix=" <span class='counter'>0</span>">
                <div class="detail-rows">
                    <div class="container">
                        <div class="no-data">No Data Found</div>
                    </div>
                </div>
            </tab>
        </tabs>
    </div>
</template>

<script>
export default {
    name: 'UserDetails'
}
</script>