<template>
    <div class="profile-wrapper">
        <div class="container">
            <div class="panel-block">
                <div class="heading">
                    <h2 class="title">Personal Information</h2>
                </div>
                <div class="panel">
                    <div class="panel-body">
                        <div class="personal-info-wrap">
                            <div class="input-cols">
                                <div class="col">
                                    <label class="control-label">First Name</label>
                                    <input type="text" class="form-control" placeholder="First Name" value="John">
                                </div>
                                <div class="col">
                                    <label class="control-label">Middle Name (Initial)</label>
                                    <input type="text" class="form-control" placeholder="Middle Name" value="Alexander">
                                </div>
                                <div class="col">
                                    <label class="control-label">Last Name</label>
                                    <input type="text" class="form-control" placeholder="Last Name" value="Smith">
                                </div>
                                <div class="col">
                                    <label class="control-label">Date of Birth</label>
                                    <input type="date" class="form-control" placeholder="Date of Birth" value="1985-03-24">
                                </div>
                            </div>
                            <div class="btn-col">
                                <label class="control-label">&nbsp;</label>
                                <button type="button" class="btn">Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="panel-block">
                <div class="heading">
                    <h2 class="title">Email Addresses</h2>
                    <span class="add-btn" @click="addEmail = true"><i class="icon"></i> Add Email</span>
                </div>
                <div class="panel">
                    <div class="panel-header">
                        <h3 class="title">Email</h3>
                        <span class="action">Action</span>
                    </div>
                    <div class="panel-body">
                        <div class="data-row">
                            <span class="data">johnsmith@gmail.com</span>
                            <span class="action">
                                <span class="remove-btn">Remove</span>
                            </span>
                        </div>
                        <div class="data-row">
                            <span class="data">john_smith@gmail.com</span>
                            <span class="action">
                                <span class="remove-btn">Remove</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="panel-block">
                <div class="heading">
                    <h2 class="title">Phone Numbers</h2>
                    <span class="add-btn" @click="addPhone = true"><i class="icon"></i>Add Phone</span>
                </div>
                <div class="panel">
                    <div class="panel-header">
                        <h3 class="title">Phone</h3>
                        <span class="action">Action</span>
                    </div>
                    <div class="panel-body">
                        <div class="data-row">
                            <span class="data">+17186935541</span>
                            <span class="action">
                                <span class="remove-btn">Remove</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="panel-block">
                <div class="heading">
                    <h2 class="title">Physical Addresses</h2>
                    <span class="add-btn" @click="addAddress = true"><i class="icon"></i>Add Address</span>
                </div>
                <div class="panel">
                    <div class="panel-header">
                        <h3 class="title">Address</h3>
                        <span class="action">Action</span>
                    </div>
                    <div class="panel-body">
                        <div class="data-row">
                            <span class="data">288 River Laurel Way, Woodstock, GA 30188, USA</span>
                            <span class="action">
                                <span class="remove-btn">Remove</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="panel-block">
                <div class="heading">
                    <h2 class="title">Alternate Names (AKA)</h2>
                    <span class="add-btn" @click="addAltName = true"><i class="icon"></i>Add Alternate Name</span>
                </div>
                <div class="panel">
                    <div class="panel-body">
                        <div class="data-empty">You have not added any alternate names yet</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <p @click="isOpen = true">Open Modal</p> -->
    <ModalComponent :open="addEmail" @close="addEmail = !addEmail">
        <div class="profile-dialog">
            <h3 class="modal-title">Add Email</h3>
            <div class="add-input-wrap">
                <div class="add-input">
                    <input type="text" class="form-control" placeholder="Enter Email">
                </div>
                <button type="submit" class="btn">Save</button>
            </div>
        </div>
    </ModalComponent>
    <ModalComponent :open="addPhone" @close="addPhone = !addPhone">
        <div class="profile-dialog">
            <h3 class="modal-title">Add Phone</h3>
            <div class="add-input-wrap">
                <div class="add-input">
                    <input type="text" class="form-control" placeholder="Enter Phone">
                </div>
                <button type="submit" class="btn">Save</button>
            </div>
        </div>
    </ModalComponent>
    <ModalComponent :open="addAddress" @close="addAddress = !addAddress">
        <div class="profile-dialog">
            <h3 class="modal-title">Add Address</h3>
            <div class="add-input-wrap">
                <div class="add-input">
                    <input type="text" class="form-control" placeholder="Enter Address">
                </div>
                <button type="submit" class="btn">Save</button>
            </div>
        </div>
    </ModalComponent>
    <ModalComponent :open="addAltName" @close="addAltName = !addAltName">
        <div class="profile-dialog">
            <h3 class="modal-title">Add Alternate Name</h3>
            <div class="add-input-wrap">
                <div class="add-input">
                    <input type="text" class="form-control" placeholder="Enter Alternate Name">
                </div>
                <button type="submit" class="btn">Save</button>
            </div>
        </div>
    </ModalComponent>
</template>

<script>
import { ref } from "vue";
import ModalComponent from "./ModalComponent.vue";

export default {
    name: 'UserProfile',
    components: {
        ModalComponent
    },
    setup() {
        const addEmail = ref(false);
        const addPhone = ref(false);
        const addAddress = ref(false);
        const addAltName = ref(false);
        return { addEmail, addPhone, addAddress, addAltName };
    }
}
</script>