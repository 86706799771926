import { createApp } from 'vue'
import App from './App.vue'
import router from './routers'
import {Tabs, Tab} from 'vue3-tabs-component';

createApp(App)
.use(router)
.component('tabs', Tabs)
.component('tab', Tab)
.mount('#app')
