<template>
    <footer class="app-footer">
        <div class="container">
            <div class="powered-by">
                <p>Powered by</p>
                <a href="https://privacybee.com/" target="_blank">
                    <img src="~@/assets/images/privacy-bee-logo.png" alt="PrivacyBee" class="footer-logo">
                </a>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'AppFooter'
}
</script>