<template>
    <div class="dashboard-wrapper">
        <div class="top-stat">
            <div class="container">
                <div class="left-panel">
                    <div class="graph" id="chartdiv"></div>
                    <div class="kpi-blocks">
                        <div class="kpi-col">
                            <h3 class="data">20</h3>
                            <p>Pending</p>
                        </div>
                        <div class="kpi-col">
                            <h3 class="data">0</h3>
                            <p>In Progress</p>
                        </div>
                        <div class="kpi-col">
                            <h3 class="data">134</h3>
                            <p>Not Found</p>
                        </div>
                        <div class="kpi-col">
                            <h3 class="data">0</h3>
                            <p>Closed</p>
                        </div>
                    </div>
                </div>
                <div class="right-panel">
                    <div class="alltime-stat">
                        <h2 class="title">All-Time Stats</h2>
                        <div class="stat-row">
                            <div class="graph-line sp"></div>
                            <p><strong class="data">1,042</strong> Scans Performed</p>
                        </div>
                        <div class="stat-row">
                            <div class="graph-line ef"></div>
                            <p><strong class="data">30</strong> Exposures Found</p>
                        </div>
                        <div class="stat-row">
                            <div class="graph-line dc"></div>
                            <p><strong class="data">22</strong> Deletions Completed</p>
                        </div>
                        <div class="stat-row">
                            <div class="graph-line dr"></div>
                            <p><strong class="data">0</strong> Deletes Requested</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="table-wrapper">
            <div class="container">
                <div class="row header-row">
                    <div class="col col-1">Name</div>
                    <div class="col col-2">Discovered</div>
                    <div class="col col-3">Last Action</div>
                    <div class="col col-4">Description</div>
                    <div class="col col-5">Status</div>
                </div>
                <div class="row data-row">
                    <div class="col col-1" data-title="Name">
                        <div class="company-name">
                            <div class="brand-logo">
                                <img src="https://cdn.privacybee.com/company-logo/production/29902.png" alt="">
                            </div>
                            <div class="vendor-details">
                                <div class="vendor-name">Industry Opt-Out Site</div>
                                <div class="vendor-info">Publishers Clearing House</div>
                            </div>
                        </div>
                    </div>
                    <div class="col col-2" data-title="Discovered">2/01/2023</div>
                    <div class="col col-3" data-title="Last Action">2/01/2023</div>
                    <div class="col col-4" data-title="Description">Deletion Requested</div>
                    <div class="col col-5" data-title="Status">
                        <div class="status">Likely Exposed</div>
                    </div>
                </div>
                <div class="row data-row">
                    <div class="col col-1" data-title="Name">
                        <div class="company-name">
                            <div class="brand-logo">
                                <img src="https://cdn.privacybee.com/company-logo/production/8666.png" alt="">
                            </div>
                            <div class="vendor-details">
                                <div class="vendor-name">Data Breach</div>
                                <div class="vendor-info">500px</div>
                            </div>
                        </div>
                    </div>
                    <div class="col col-2" data-title="Discovered">7/05/2018</div>
                    <div class="col col-3" data-title="Last Action">-</div>
                    <div class="col col-4" data-title="Description">Deletion Requested</div>
                    <div class="col col-5" data-title="Status">
                        <div class="status">Exposed</div>
                    </div>
                </div>
                <div class="row data-row">
                    <div class="col col-1" data-title="Name">
                        <div class="company-name">
                            <div class="brand-logo">
                                <img src="https://cdn.privacybee.com/company-logo/production/22229.png" alt="">
                            </div>
                            <div class="vendor-details">
                                <div class="vendor-name">Data Breach</div>
                                <div class="vendor-info">Trello</div>
                            </div>
                        </div>
                    </div>
                    <div class="col col-2" data-title="Discovered">1/16/2024</div>
                    <div class="col col-3" data-title="Last Action">-</div>
                    <div class="col col-4" data-title="Description">Deletion Requested</div>
                    <div class="col col-5" data-title="Status">
                        <div class="status">Exposed</div>
                    </div>
                </div>
                <div class="row data-row">
                    <div class="col col-1" data-title="Name">
                        <div class="company-name">
                            <div class="brand-logo">
                                <img src="https://cdn.privacybee.com/company-logo/production/6218.png" alt="">
                            </div>
                            <div class="vendor-details">
                                <div class="vendor-name">Data Breach</div>
                                <div class="vendor-info">Zomato</div>
                            </div>
                        </div>
                    </div>
                    <div class="col col-2" data-title="Discovered">5/17/2017</div>
                    <div class="col col-3" data-title="Last Action">-</div>
                    <div class="col col-4" data-title="Description">Deletion Requested</div>
                    <div class="col col-5" data-title="Status">
                        <div class="status">Exposed</div>
                    </div>
                </div>
                <div class="row data-row">
                    <div class="col col-1" data-title="Name">
                        <div class="company-name">
                            <div class="brand-logo">
                                <img src="https://cdn.privacybee.com/company-logo/production/2823.png" alt="">
                            </div>
                            <div class="vendor-details">
                                <div class="vendor-name">Data Breach</div>
                                <div class="vendor-info">Dropbox</div>
                            </div>
                        </div>
                    </div>
                    <div class="col col-2" data-title="Discovered">7/01/2012</div>
                    <div class="col col-3" data-title="Last Action">-</div>
                    <div class="col col-4" data-title="Description">Deletion Requested</div>
                    <div class="col col-5" data-title="Status">
                        <div class="status">Exposed</div>
                    </div>
                </div>
                <div class="row data-row">
                    <div class="col col-1" data-title="Name">
                        <div class="company-name">
                            <div class="brand-logo">
                                <img src="https://cdn.privacybee.com/company-logo/production/90.png" alt="">
                            </div>
                            <div class="vendor-details">
                                <div class="vendor-name">Search Engine Result</div>
                                <div class="vendor-info">LinkedIn</div>
                            </div>
                        </div>
                    </div>
                    <div class="col col-2" data-title="Discovered">2/10/2022</div>
                    <div class="col col-3" data-title="Last Action">-</div>
                    <div class="col col-4" data-title="Description">Deletion Requested</div>
                    <div class="col col-5" data-title="Status">
                        <div class="status">Exposed</div>
                    </div>
                </div>
                <div class="row data-row">
                    <div class="col col-1" data-title="Name">
                        <div class="company-name">
                            <div class="brand-logo">
                                <img src="https://cdn.privacybee.com/company-logo/production/10549.png" alt="">
                            </div>
                            <div class="vendor-details">
                                <div class="vendor-name">Search Engine Result</div>
                                <div class="vendor-info">Google</div>
                            </div>
                        </div>
                    </div>
                    <div class="col col-2" data-title="Discovered">2/10/2022</div>
                    <div class="col col-3" data-title="Last Action">-</div>
                    <div class="col col-4" data-title="Description">Deletion Requested</div>
                    <div class="col col-5" data-title="Status">
                        <div class="status">Exposed</div>
                    </div>
                </div>
                <div class="row data-row">
                    <div class="col col-1" data-title="Name">
                        <div class="company-name">
                            <div class="brand-logo">
                                <img src="https://cdn.privacybee.com/company-logo/production/61894.png" alt="">
                            </div>
                            <div class="vendor-details">
                                <div class="vendor-name">Search Engine Result</div>
                                <div class="vendor-info">OpenReview</div>
                            </div>
                        </div>
                    </div>
                    <div class="col col-2" data-title="Discovered">2/10/2022</div>
                    <div class="col col-3" data-title="Last Action">3/01/2022</div>
                    <div class="col col-4" data-title="Description">Deletion Requested</div>
                    <div class="col col-5" data-title="Status">
                        <div class="status">Exposed</div>
                    </div>
                </div>
                <div class="row data-row">
                    <div class="col col-1" data-title="Name">
                        <div class="company-name">
                            <div class="brand-logo">
                                <img src="https://cdn.privacybee.com/company-logo/production/35730.png" alt="">
                            </div>
                            <div class="vendor-details">
                                <div class="vendor-name">Data Breach</div>
                                <div class="vendor-info">Gravatar</div>
                            </div>
                        </div>
                    </div>
                    <div class="col col-2" data-title="Discovered">10/03/2020</div>
                    <div class="col col-3" data-title="Last Action">6/11/2021</div>
                    <div class="col col-4" data-title="Description">Deletion Requested</div>
                    <div class="col col-5" data-title="Status">
                        <div class="status">Exposed</div>
                    </div>
                </div>
                <div class="row data-row">
                    <div class="col col-1" data-title="Name">
                        <div class="company-name">
                            <div class="brand-logo">
                                <img src="https://cdn.privacybee.com/company-logo/production/48153.png" alt="">
                            </div>
                            <div class="vendor-details">
                                <div class="vendor-name">Search Engine Result</div>
                                <div class="vendor-info">Kansas State University</div>
                            </div>
                        </div>
                    </div>
                    <div class="col col-2" data-title="Discovered">2/10/2022</div>
                    <div class="col col-3" data-title="Last Action">12/20/2023</div>
                    <div class="col col-4" data-title="Description">Deletion Requested</div>
                    <div class="col col-5" data-title="Status">
                        <div class="status">Exposed</div>
                    </div>
                </div>
                <div class="row data-row">
                    <div class="col col-1" data-title="Name">
                        <div class="company-name">
                            <div class="brand-logo">
                                <img src="https://cdn.privacybee.com/company-logo/production/182.png" alt="">
                            </div>
                            <div class="vendor-details">
                                <div class="vendor-name">Data Breach</div>
                                <div class="vendor-info">Disqus</div>
                            </div>
                        </div>
                    </div>
                    <div class="col col-2" data-title="Discovered">7/01/2012</div>
                    <div class="col col-3" data-title="Last Action">12/10/2023</div>
                    <div class="col col-4" data-title="Description">Deletion Requested</div>
                    <div class="col col-5" data-title="Status">
                        <div class="status">Exposed</div>
                    </div>
                </div>
                <div class="row data-row">
                    <div class="col col-1" data-title="Name">
                        <div class="company-name">
                            <div class="brand-logo">
                                <img src="https://cdn.privacybee.com/company-logo/production/37749.png" alt="">
                            </div>
                            <div class="vendor-details">
                                <div class="vendor-name">Data Breach</div>
                                <div class="vendor-info">000WebHost</div>
                            </div>
                        </div>
                    </div>
                    <div class="col col-2" data-title="Discovered">3/01/2015</div>
                    <div class="col col-3" data-title="Last Action">-</div>
                    <div class="col col-4" data-title="Description">Deletion Requested</div>
                    <div class="col col-5" data-title="Status">
                        <div class="status">Exposed</div>
                    </div>
                </div>
                <div class="row data-row">
                    <div class="col col-1" data-title="Name">
                        <div class="company-name">
                            <div class="brand-logo">
                                <img src="https://cdn.privacybee.com/company-logo/production/122833.png" alt="">
                            </div>
                            <div class="vendor-details">
                                <div class="vendor-name">Data Breach</div>
                                <div class="vendor-info">Bigbasket</div>
                            </div>
                        </div>
                    </div>
                    <div class="col col-2" data-title="Discovered">10/14/2020</div>
                    <div class="col col-3" data-title="Last Action">-</div>
                    <div class="col col-4" data-title="Description">Deletion Requested</div>
                    <div class="col col-5" data-title="Status">
                        <div class="status">Exposed</div>
                    </div>
                </div>
                <div class="row data-row">
                    <div class="col col-1" data-title="Name">
                        <div class="company-name">
                            <div class="brand-logo">
                                <img src="https://cdn.privacybee.com/company-logo/production/158924.png" alt="">
                            </div>
                            <div class="vendor-details">
                                <div class="vendor-name">Data Breach</div>
                                <div class="vendor-info">Digimon</div>
                            </div>
                        </div>
                    </div>
                    <div class="col col-2" data-title="Discovered">9/05/2016</div>
                    <div class="col col-3" data-title="Last Action">-</div>
                    <div class="col col-4" data-title="Description">Deletion Requested</div>
                    <div class="col col-5" data-title="Status">
                        <div class="status">Exposed</div>
                    </div>
                </div>
                <div class="row data-row">
                    <div class="col col-1" data-title="Name">
                        <div class="company-name">
                            <div class="brand-logo">
                                <img src="https://cdn.privacybee.com/company-logo/production/158853.png" alt="">
                            </div>
                            <div class="vendor-details">
                                <div class="vendor-name">Data Breach</div>
                                <div class="vendor-info">Domino's USA</div>
                            </div>
                        </div>
                    </div>
                    <div class="col col-2" data-title="Discovered">3/24/2021</div>
                    <div class="col col-3" data-title="Last Action">-</div>
                    <div class="col col-4" data-title="Description">Deletion Requested</div>
                    <div class="col col-5" data-title="Status">
                        <div class="status">Exposed</div>
                    </div>
                </div>
                <div class="row data-row">
                    <div class="col col-1" data-title="Name">
                        <div class="company-name">
                            <div class="brand-logo">
                                <img src="https://cdn.privacybee.com/company-logo/production/144318.png" alt="">
                            </div>
                            <div class="vendor-details">
                                <div class="vendor-name">Data Breach</div>
                                <div class="vendor-info">zoomcar.com</div>
                            </div>
                        </div>
                    </div>
                    <div class="col col-2" data-title="Discovered">7/01/2018</div>
                    <div class="col col-3" data-title="Last Action">-</div>
                    <div class="col col-4" data-title="Description">Deletion Requested</div>
                    <div class="col col-5" data-title="Status">
                        <div class="status">Exposed</div>
                    </div>
                </div>
                <div class="row data-row">
                    <div class="col col-1" data-title="Name">
                        <div class="company-name">
                            <div class="brand-logo">
                                <img src="https://cdn.privacybee.com/company-logo/production/11941.jpeg" alt="">
                            </div>
                            <div class="vendor-details">
                                <div class="vendor-name">Search Engine Result</div>
                                <div class="vendor-info">City of Hope</div>
                            </div>
                        </div>
                    </div>
                    <div class="col col-2" data-title="Discovered">2/10/2022</div>
                    <div class="col col-3" data-title="Last Action">-</div>
                    <div class="col col-4" data-title="Description">Deletion Requested</div>
                    <div class="col col-5" data-title="Status">
                        <div class="status">Exposed</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

export default {
    name: 'UserDashboard',
    mounted() {
        this.renderChart()
    },
    methods: {
        renderChart(){
            am4core.addLicense('CH271623187')
            am4core.useTheme(am4themes_animated)
            // Create chart instance
            const chart = am4core.create("chartdiv", am4charts.XYChart);

            chart.colors.list = [
                am4core.color("#BEE6BE"),
                am4core.color("#cce3f5"),
                am4core.color("#f3beb9")
            ];
            // Add data
            chart.data = [{
                "year": "2024",
                "europe": "5",
                "namerica": "3",
                "asia": "4"
            }];

            // Create axes
            var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "year";
            categoryAxis.renderer.grid.template.location = 0;
            categoryAxis.renderer.grid.template.disabled = true
            categoryAxis.renderer.labels.template.disabled = true

            var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.renderer.inside = true;
            valueAxis.renderer.labels.template.disabled = true;
            valueAxis.min = 0;

            // Create series
            function createSeries(field) {
            
                // Set up series
                var series = chart.series.push(new am4charts.ColumnSeries());
                series.name = "Exposures Found";
                series.dataFields.valueY = field;
                series.dataFields.categoryX = "year";
                series.sequencedInterpolation = true;
                
                // Make it stacked
                series.stacked = true;
                
                // Configure columns
                series.columns.template.width = am4core.percent(100);
                series.columns.template.tooltipText = "[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}";
                
                return series;
            }

            createSeries("europe", "Europe");
            createSeries("namerica", "North America");
            createSeries("asia", "Asia-Pacific");

            // Legend
            //chart.legend = new am4charts.Legend();
        }
    }
}
</script>