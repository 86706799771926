<template>
    <header class="app-header">
        <div class="container">
            <div class="hamburger">
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
            </div>
            <nav class="navbar">
                <ul>
                    <li>
                        <router-link to="/">Dashboard</router-link>
                    </li>
                    <li>
                        <router-link to="/details">Details</router-link>
                    </li>
                    <li>
                        <router-link to="/profile">Profile</router-link>
                    </li>
                </ul>
            </nav>
            <div class="user-dropdown">
                <div class="select-menu">
                    <div class="select-btn">
                        <div class="initial">SB</div>
                        <div class="vp">
                            <p>Viewing Person</p>
                            <span class="sBtn-text">Steve Banks</span>
                        </div>
                        <i class="arrow"></i>
                    </div>

                    <ul class="options">
                        <li class="option">
                            <router-link to="/">
                                <span class="option-text">Steve Banks</span>
                            </router-link>
                            <router-link to="/profile">
                                <span class="cog"></span>
                            </router-link>
                        </li>
                        <li class="option">
                            <router-link to="/">
                                <span class="option-text">Kathrine Banks</span>
                            </router-link>
                            <router-link to="/profile">
                                <span class="cog"></span>
                            </router-link>
                        </li>
                        <li class="option">
                            <router-link to="/">
                                <span class="option-text">Andrew Banks</span>
                            </router-link>
                            <router-link to="/profile">
                                <span class="cog"></span>
                            </router-link>
                        </li>
                        <li class="manage-family" @click="manageFamily = true">
                            <span>Manage Family</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </header>
    <ModalComponent :open="manageFamily" @close="manageFamily = !manageFamily">
        <div class="family-dialog">
            <h3 class="modal-title">Manage Family</h3>
            <p class="sub-title">Protecting 3 of 5 people</p>
            <div class="member-details">
                <div class="row header-row">
                    <div class="col col-1">Name</div>
                    <div class="col col-2">Email</div>
                    <div class="col col-3">Age</div>
                    <div class="col col-4">Last Action</div>
                    <div class="col col-5"># Scans</div>
                    <div class="col col-6"># Removals</div>
                    <div class="col col-7">Identity Safe</div>
                    <div class="col col-8">Action</div>
                </div>
                <div class="row data-row">
                    <div class="col col-1" data-title="Name">
                        <div class="member-name">
                            <div class="initial">SB</div>
                            <div class="name">Steve Banks</div>
                        </div>
                    </div>
                    <div class="col col-2" data-title="Email">jdoe@gmail.com</div>
                    <div class="col col-3" data-title="Age">38</div>
                    <div class="col col-4" data-title="Last Action">12/03/2023</div>
                    <div class="col col-5" data-title="# Scans">4</div>
                    <div class="col col-6" data-title="# Removals">3</div>
                    <div class="col col-7" data-title="Identity Safe">
                        <div class="safe-status success">Protected</div>
                    </div>
                    <div class="col col-8" data-title="Action">
                        <span class="del-row"></span>
                    </div>
                </div>
                <div class="row data-row">
                    <div class="col col-1" data-title="Name">
                        <div class="member-name">
                            <div class="initial">KB</div>
                            <div class="name">Kathrine Banks</div>
                        </div>
                    </div>
                    <div class="col col-2" data-title="Email">jdoe@gmail.com</div>
                    <div class="col col-3" data-title="Age">30</div>
                    <div class="col col-4" data-title="Last Action">22/02/2023</div>
                    <div class="col col-5" data-title="# Scans">3</div>
                    <div class="col col-6" data-title="# Removals">2</div>
                    <div class="col col-7" data-title="Identity Safe">
                        <div class="safe-status error">Missing Info</div>
                    </div>
                    <div class="col col-8" data-title="Action">
                        <span class="del-row"></span>
                    </div>
                </div>
                <div class="row data-row">
                    <div class="col col-1" data-title="Name">
                        <div class="member-name">
                            <div class="initial">AB</div>
                            <div class="name">Andrew Banks</div>
                        </div>
                    </div>
                    <div class="col col-2" data-title="Email">jdoe@gmail.com</div>
                    <div class="col col-3" data-title="Age">8</div>
                    <div class="col col-4" data-title="Last Action">2/01/2023</div>
                    <div class="col col-5" data-title="# Scans">6</div>
                    <div class="col col-6" data-title="# Removals">4</div>
                    <div class="col col-7" data-title="Identity Safe">
                        <div class="safe-status success">Protected</div>
                    </div>
                    <div class="col col-8" data-title="Action">
                        <span class="del-row"></span>
                    </div>
                </div>
            </div>
            <div class="dialog-footer">
                <h3 class="title">Add Family Member</h3>
                <div class="add-member">
                    <div class="col">
                        <div class="add-input">
                            <input type="text" class="form-control" placeholder="First Name">
                        </div>
                    </div>
                    <div class="col">
                        <div class="add-input">
                            <input type="text" class="form-control" placeholder="Middle Name">
                        </div>
                    </div>
                    <div class="col">
                        <div class="add-input">
                            <input type="text" class="form-control" placeholder="Last Name">
                        </div>
                    </div>
                    <div class="col">
                        <button type="submit" class="btn">Add New Member</button>
                    </div>
                </div>
            </div>
        </div>
  </ModalComponent>
</template>

<script>
import { ref } from "vue";
import ModalComponent from "./ModalComponent.vue";

export default {
    name: 'AppHeader',
    mounted() {
        let hamburger = document.querySelector('.hamburger');
        let menu = document.querySelector('.navbar');
        //let menuItems = document.querySelector('.navbar ul li a');

        hamburger.addEventListener('click', function() {
            hamburger.classList.toggle('isactive');
            menu.classList.toggle('active');
        });
        // menuItems.addEventListener('click', function() {
        //     hamburger.classList.remove('isactive');
        //     menu.classList.remove('active');
        // });

        const optionMenu = document.querySelector(".select-menu"),
            options = optionMenu.querySelectorAll(".option"),
            sBtn_text = optionMenu.querySelector(".sBtn-text");

        document.addEventListener("click", toggleDropdown);
        function toggleDropdown(event) {
            if (event.target.classList.contains("select-btn")){
                optionMenu.classList.toggle("active")
            } else {
                optionMenu.classList.remove("active")
            }
        }

        options.forEach((option) => {
            option.addEventListener("click", () => {
                let selectedOption = option.querySelector(".option-text").innerText;
                sBtn_text.innerText = selectedOption;
                optionMenu.classList.remove("active");
            });
        });
    },
    created: function(){
        //console.log('dddd')
    },
    components: {
        ModalComponent
    },
    setup() {
        const manageFamily = ref(false);

        return { manageFamily };
    }
}
</script>