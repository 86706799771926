<template>
    <transition name="fade">
      <div class="vue-modal" v-show="open">
        <transition name="drop-in">
          <div class="vue-modal-inner" v-show="open">
            <div class="vue-modal-content">
              <slot />
              <button type="button" @click="close" class="modal-close">Close</button>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </template>
  
  <script>
  import { onMounted, onUnmounted } from "vue";
  
  export default {
    props: {
      open: {
        type: Boolean,
        required: true,
      },
    },
    setup(_, { emit }) {
      const close = () => {
        emit("close");
      };
  
      const handleKeyup = (event) => {
        if (event.keyCode === 27) {
          close();
        }
      };
  
      onMounted(() => document.addEventListener("keyup", handleKeyup));
      onUnmounted(() => document.removeEventListener("keyup", handleKeyup));
  
      return { close };
    },
  };
  </script>
  
  <style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  
  .drop-in-enter-active,
  .drop-in-leave-active {
    transition: all 0.3s ease-out;
  }
  
  .drop-in-enter-from,
  .drop-in-leave-to {
    opacity: 0;
    transform: translateY(-50px);
  }
  </style>